import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { usePhrases } from 'contexts/ConfigContext';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { Alert } from 'react-bootstrap';
import { BillMode, FormattedBill } from 'types/models/PayMyBill';

interface BillPaymentButtonsProps {
  bill: FormattedBill;
  handleChangeDisplay: (mode: BillMode) => void;
  handlePaymentAmount: (amount: number) => void;
}

export const BillPaymentButtons: React.FC<BillPaymentButtonsProps> = ({
  bill,
  handleChangeDisplay,
  handlePaymentAmount,
}) => {
  const { payMyBillBalanceText } = usePhrases();
  const { braintreeLoading } = usePayMyBill();

  const handlePartPayment = (mode: BillMode) => {
    window.scrollTo(0, 0);
    handleChangeDisplay(mode);
  };

  return bill.outstandingBalanceToPay > 0 ? (
    <div className="bill-payment-btns">
      <button
        className="btn btn-primary checkout-fw-button"
        onClick={() => handlePaymentAmount(bill.outstandingBalanceToPay)}
        disabled={braintreeLoading}
      >
        {`Pay ${payMyBillBalanceText} (`}
        <FormattedCurrency value={bill.outstandingBalanceToPay} />
        {`)`}
      </button>
      <button
        className="btn btn-primary checkout-fw-button"
        onClick={() => handlePartPayment(BillMode.showSplit)}
        disabled={braintreeLoading}
      >
        Pay an even split
      </button>
      <button
        className="btn btn-primary checkout-fw-button"
        onClick={() => handlePartPayment(BillMode.showCustom)}
        disabled={braintreeLoading}
      >
        Pay a custom amount
      </button>
    </div>
  ) : (
    <Alert bsStyle="success" className="text-center">
      This account has been settled
    </Alert>
  );
};
