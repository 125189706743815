import { useEffect } from 'react';
import { Panel } from 'react-bootstrap';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInUser } from 'user/reducer';
import { setLoginMethod, storeGuestEmail } from 'user/actions';
import { LoginMethod, User } from 'types/models/User';
import { WLWOLocationState } from 'types/models/WLWOLocationState';
import { useTables } from 'contexts/TableContext';
import { FormattedCurrency } from 'common/components/FormattedCurrency';

interface ParamsProps {
  accountId: string;
  outstandingBalance: string;
  accountNumber: string;
}

export const CompletePmb: React.FC = () => {
  const { accountId, accountNumber, outstandingBalance } =
    useParams<ParamsProps>();
  const dispatch = useDispatch();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { selectedTable } = useTables();

  const hasRedirectParams = Boolean(
    selectedVenue && selectedService && selectedSalesArea && selectedTable,
  );

  const outstandingBalanceToPay = outstandingBalance
    ? Number(outstandingBalance)
    : 0;

  const user = useSelector(selectLoggedInUser);

  const location = useLocation<WLWOLocationState>();

  const handleClearBill = (user: User) => {
    if (user.loginMethod === LoginMethod.Anonymous) {
      dispatch(setLoginMethod());
      dispatch(storeGuestEmail());
    }
  };

  useEffect(() => {
    return () => {
      handleClearBill(user);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (
    !(
      location?.state?.from?.pathname === '/checkout/pay-my-bill' && accountId
    ) &&
    !hasRedirectParams
  ) {
    return <Redirect to="/" />;
  }

  return (
    <div className="checkout-complete-container">
      <Panel>
        <h2>Thank you for your payment.</h2>
        <p>
          {outstandingBalanceToPay > 0 ? (
            <>
              A payment confirmation will be emailed to you.
              <br />
              <strong>
                {selectedTable?.label} still has{' '}
                <FormattedCurrency value={outstandingBalanceToPay} /> to pay.
              </strong>
            </>
          ) : (
            `Your bill for ${selectedTable?.label} has now been settled and a payment confirmation will be emailed to you.`
          )}
        </p>

        <p>Account reference: #{accountNumber}</p>
        <div className="pmb-complete-btns">
          <Link className="btn btn-primary" to={`/venue/${selectedVenue?.id}`}>
            Back to homepage
          </Link>
          <Link
            className="btn btn-default"
            to={`/venue/${selectedVenue?.id}/sales-area/${selectedSalesArea?.id}/service/${selectedService}/account/${accountId}`}
          >
            View bill
          </Link>
        </div>
      </Panel>
    </div>
  );
};
