import { createContext, useContext, useEffect, useState } from 'react';

import { AxiosRequestConfig } from 'axios';
import useAxios from 'axios-hooks';
import { Helmet } from 'react-helmet-async';
import { requestBodyFormatter } from 'api/utils';
import {
  ErrorResponse,
  GetConfigResponse,
  GetStylesResponse,
  WLWOConfig,
  WLWOPhrases,
} from 'types/models';
import { Loader } from 'components/Loader';
import { useAPI } from 'api/useAPI';
import { toBoolean } from 'utils';
import { Environment } from 'types';

import errorImage from 'images/undraw_towing_6yy4.svg';

import './ConfigContext.scss';
import {
  defaultConfig,
  defaultPhrases,
} from 'contexts/ConfigContext/Defaults';

const defaultCaloriePhraseUsLocale = 'Adults need around 2000 Cal a day';

interface ConfigContextProviderProps {
  children: React.ReactNode;
  env: string;
}

export interface ConfigContext {
  config: WLWOConfig;
  phrases: WLWOPhrases;
  setConfig: (configKey: string, value: unknown) => void;
  setPhrases: (configKey: string, value: unknown) => void;
}

export interface SetConfigProps {
  setConfig: (configKey: string, value: unknown) => void;
}

// set up context
export const ConfigContext = createContext<ConfigContext>({
  config: defaultConfig,
  phrases: defaultPhrases,
  setConfig: () => {
    throw Error('Context not set up yet');
  },
  setPhrases: () => {
    throw Error('Context not set up yet');
  },
});

// set up hook
export const useConfig = (): WLWOConfig => {
  const consumer = useContext(ConfigContext);

  // just return inner config
  return consumer.config;
};

export const usePhrases = (): WLWOPhrases => {
  const consumer = useContext(ConfigContext);

  // just return inner config
  return consumer.phrases;
};

export const useSetConfig = (): ((
  configKey: string,
  value: unknown,
) => void) => {
  const consumer = useContext(ConfigContext);

  // just return setConfig function
  return consumer.setConfig;
};

export const useSetPhrases = (): ((
  configKey: string,
  value: unknown,
) => void) => {
  const consumer = useContext(ConfigContext);

  // just return setPhrases function
  return consumer.setPhrases;
};

// set up provider
export const ConfigContextProvider: React.FC<ConfigContextProviderProps> = ({
  children,
  env,
}) => {
  const cssUrl: string = window.env.REACT_APP_CLIENT_STYLE as string;

  const { url, getConfig, getStyles } = useAPI();

  const [config, setConfig] = useState<WLWOConfig>(defaultConfig);
  const [phrases, setPhrases] = useState<WLWOPhrases>(defaultPhrases);

  const [initError, setInitError] = useState<string | undefined>();

  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [styleLoaded, setStyleLoaded] = useState<boolean>(false);

  const getConfigFetchParams = getConfig();
  // set axios hook options
  const configOptions: AxiosRequestConfig = {
    url: url,
    method: 'POST',
    headers: getConfig().headers,
    data: requestBodyFormatter({
      method: getConfigFetchParams.method,
      ...getConfigFetchParams.body,
    }),
  };

  const stylesConfig = getStyles();

  const styleOptions: AxiosRequestConfig = {
    url: url,
    method: 'POST',
    headers: stylesConfig.headers,
    data: requestBodyFormatter({
      method: stylesConfig.method,
      ...stylesConfig.body,
    }),
  };

  const cssOptions: AxiosRequestConfig = {
    url: cssUrl,
    headers: getConfigFetchParams.headers,
    method: 'GET',
  };

  // TODO: how to deal with loading from config.js
  const [{ data: configData, error: configError }] = useAxios<
    GetConfigResponse | ErrorResponse
  >(configOptions);

  const [{ data: styleData, error: styleError }] = useAxios<
    GetStylesResponse | ErrorResponse
  >(styleOptions);

  const [{ data: cssData, loading: cssLoading, error: cssError }] =
    useAxios(cssOptions);

  // deal with data payload
  useEffect(() => {
    if (configData) {
      const configResponse = configData as GetConfigResponse;
      if ((configData as ErrorResponse)?.detail) {
        setInitError((configData as ErrorResponse)?.detail);
      } else if ((configData as GetConfigResponse) === undefined) {
        setInitError("Client config doesn't look to have been completed");
      } else {
        const newConfig: WLWOConfig = {
          ...config,
          ...configResponse,
          env: env as Environment,
          acceptsPayment: toBoolean(configResponse.acceptsPayment),
          acceptsTip: toBoolean(configResponse.acceptsTip),
          choicesFirstInLine: toBoolean(configResponse.choicesFirstInLine),
          compactChoicesView: toBoolean(configResponse.compactChoicesView),
          currencySymbolsDisabled: toBoolean(
            configResponse.currencySymbolsDisabled,
          ),
          disableIncrementingForDefaults: toBoolean(
            configResponse.disableIncrementingForDefaults,
          ),
          disableSearch: toBoolean(configResponse.disableSearch),
          disableUserSignIn: toBoolean(configResponse.disableUserSignIn),
          distanceFormatMiles: toBoolean(configResponse.distanceFormatMiles),
          enableClickAndCollect: toBoolean(
            configResponse.enableClickAndCollect,
          ),
          enableOrderAndPay: toBoolean(configResponse.enableOrderAndPay),
          galleryViewEnabled: toBoolean(
            configResponse.templates?.menu === 'gallery' || false,
          ),
          logoLinkToHomepage: toBoolean(configResponse.logoLinkToHomepage),
          // If value is < 1, set to 1. If it's greater than 1 or undefined, set it to the value
          // undefined here means infinite redemptions
          maxNumberOfVouchers:
            Number.isInteger(configResponse.maxNumberOfVouchers) === false
              ? undefined
              : Number(configResponse.maxNumberOfVouchers) !== Number.NaN &&
                Number(configResponse.maxNumberOfVouchers) < 1
              ? 1
              : Number(configResponse.maxNumberOfVouchers),
          menuAutoScrollDisabled: toBoolean(
            configResponse.menuAutoScrollDisabled,
          ),
          multipleMenusEnabled: toBoolean(configResponse.multipleMenusEnabled),
          openingTimes24hFormat: toBoolean(
            configResponse.openingTimes24hFormat,
          ),
          openingTimesRemovedFromTimeslotModal: toBoolean(
            configResponse.openingTimesRemovedFromTimeslotModal,
          ),
          pickupTimeOnTop: toBoolean(configResponse.pickupTimeOnTop),
          pickupTimeUnderHeader: toBoolean(
            configResponse.pickupTimeUnderHeader,
          ),
          productSelectionIndicatorsEnabled: toBoolean(
            configResponse.productSelectionIndicatorsEnabled,
          ),
          removeBackLink: toBoolean(configResponse.removeBackLink),
          searchPredictionEnabled: toBoolean(
            configResponse.searchPredictionEnabled,
          ),
          showRestaurantTitleOnTop: toBoolean(
            configResponse.showRestaurantTitleOnTop,
          ),
          showVenueHomeBanners: toBoolean(
            configResponse.showVenueHomeBanners ??
              defaultConfig.showVenueHomeBanners,
          ),
          showVenueHeaderBanner: toBoolean(
            configResponse.showVenueHeaderBanner ??
              defaultConfig.showVenueHeaderBanner,
          ),
          specialRequestsDisabled: toBoolean(
            configResponse.specialRequestsDisabled,
          ),
          stickyBasketEnabled: toBoolean(configResponse.stickyBasketEnabled),
          supportsOrderDestinations: toBoolean(
            configResponse.supportsOrderDestinations,
          ),
          tableSelectorSearchEnabled: toBoolean(
            configResponse.tableSelectorSearchEnabled ??
              defaultConfig.tableSelectorSearchEnabled,
          ),
          timeslots24hFormat: toBoolean(configResponse.timeslots24hFormat),
          tipPresets:
            configResponse.tipPresets && configResponse.tipPresets.length > 0
              ? configResponse.tipPresets
              : defaultConfig.tipPresets,
          venueDetailsExpandedByDefault: toBoolean(
            configResponse.venueDetailsExpandedByDefault,
          ),
          viewMenuButtonOnTop: toBoolean(configResponse.viewMenuButtonOnTop),
          vouchersEnabled: toBoolean(configResponse.vouchersEnabled),
          wlaAspectRatioEnabled: toBoolean(
            configResponse?.wlaAspectRatioEnabled,
          ),
        };

        setConfig(newConfig);

        // TODO: remove this eventually
        window.config = newConfig;

        // set additional phrases
        const newPhrases: WLWOPhrases = {
          ...phrases,
          basket: configResponse?.dictionary?.basket || defaultPhrases.basket,
          branchTitle:
            configResponse?.dictionary?.branchTitle ||
            defaultPhrases.branchTitle,
          cardNumber:
            configResponse?.dictionary?.cardNumber || defaultPhrases.cardNumber,
          cvvNumber:
            configResponse?.dictionary?.cvvNumber || defaultPhrases.cvvNumber,
          landingHeader:
            configResponse?.dictionary?.landingHeader ||
            defaultPhrases.landingHeader,
          homeText: configResponse.homeText || defaultPhrases.homeText,
          menus: configResponse?.dictionary?.menus || defaultPhrases.menus,
          // reordermenuIntroduction: configResponse?dictionary?.reordermenuIntroduction || '',
          postalCode:
            configResponse?.dictionary?.postalCode || defaultPhrases.postalCode,
          selectVenue:
            configResponse?.dictionary?.selectVenue ||
            defaultPhrases.selectVenue,
        };

        setPhrases(newPhrases);
      }
      setConfigLoaded(true);
    }

    // eslint-disable-next-line
  }, [configData]);

  useEffect(() => {
    const styleResponse: GetStylesResponse = styleData as GetStylesResponse;

    //wait for the configLoaded state update so we don't override it accidentally
    if (styleResponse?.response === 'OK' && configLoaded) {
      // update phrases
      const newPhrases: WLWOPhrases = {
        ...phrases,
        allergenPrompts:
          styleResponse?.style?.allergenPrompt?.allergenPrompts ||
          defaultPhrases.allergenPrompts,
        allergensTitleLabel:
          styleResponse?.style?.ordering?.allergensTitleLabel ||
          defaultPhrases.allergensTitleLabel,
        additionalInformation: {
          headline:
            styleResponse?.style?.additionalInformation?.headline ||
            defaultPhrases.additionalInformation.headline,
          subtitle:
            styleResponse?.style?.additionalInformation?.subtitle ||
            defaultPhrases.additionalInformation.subtitle,
          supportingText:
            styleResponse?.style?.additionalInformation?.supportingText ||
            defaultPhrases.additionalInformation.supportingText,
          usagePhrase:
            styleResponse?.style?.ordering?.additionalInformationUsagePhrase ||
            defaultPhrases.additionalInformation.usagePhrase,
        },
        chargeToRoom: {
          buttonTitle:
            styleResponse?.style?.chargeToRoom?.buttonTitle ||
            defaultPhrases.chargeToRoom.buttonTitle,
          folioSelector: {
            supportingText:
              styleResponse?.style?.chargeToRoom?.folioSelector
                ?.supportingText ||
              defaultPhrases.chargeToRoom.folioSelector.supportingText,
          },
          roomSelector: {
            button: {
              title:
                styleResponse?.style?.chargeToRoom?.roomSelector?.button
                  ?.title ||
                defaultPhrases.chargeToRoom.roomSelector.button.title,
            },
            guestNamePhrase:
              styleResponse?.style?.chargeToRoom?.roomSelector
                ?.guestNamePhrase ||
              defaultPhrases.chargeToRoom.roomSelector.guestNamePhrase,
            roomNumberPhrase:
              styleResponse?.style?.chargeToRoom?.roomSelector
                ?.roomNumberPhrase ||
              defaultPhrases.chargeToRoom.roomSelector.roomNumberPhrase,
            subtitle:
              styleResponse?.style?.chargeToRoom?.roomSelector?.subtitle ||
              defaultPhrases.chargeToRoom.roomSelector.subtitle,
            supportingText:
              styleResponse?.style?.chargeToRoom?.roomSelector
                ?.supportingText ||
              defaultPhrases.chargeToRoom.roomSelector.supportingText,
            title:
              styleResponse?.style?.chargeToRoom?.roomSelector?.title ||
              defaultPhrases.chargeToRoom.roomSelector.title,
          },
        },
        dailyCalorieAllowancePhrase: getDefaultDailyCaloriePhrase(
          config.currency,
          defaultPhrases.dailyCalorieAllowancePhrase,
          styleResponse?.style?.calories?.dailyCalorieAllowancePhrase,
        ),
        deleteAccount: {
          confirmation: {
            supportingText:
              styleResponse?.style?.deleteAccount?.confirmation
                ?.supportingText ??
              defaultPhrases.deleteAccount.confirmation.supportingText,
            title:
              styleResponse?.style?.deleteAccount?.confirmation?.title ??
              defaultPhrases.deleteAccount.confirmation.title,
          },
          prompt: {
            supportingText:
              styleResponse?.style?.deleteAccount?.prompt.supportingText ??
              defaultPhrases.deleteAccount.prompt.supportingText,
          },
        },
        deliveryToLocation: {
          deliveryTimePhrase:
            styleResponse?.style?.deliveryToLocation?.deliveryTimePhrase ||
            defaultPhrases.deliveryToLocation.deliveryTimePhrase,
          orderInformationPhrase:
            styleResponse?.style?.deliveryToLocation?.orderInformationPhrase ||
            defaultPhrases.deliveryToLocation.orderInformationPhrase,
        },
        folioPhrase:
          styleResponse?.style?.global?.folioPhrase ||
          defaultPhrases.folioPhrase,
        giftCard: {
          callToActionTitle:
            styleResponse?.style?.giftCard?.callToActionTitle ||
            defaultPhrases.giftCard.callToActionTitle,
          giftCardPhrase:
            styleResponse?.style?.giftCard?.giftCardPhrase ||
            defaultPhrases.giftCard.giftCardPhrase,
          redeemButtonTitle:
            styleResponse?.style?.giftCard?.redeemButtonTitle ||
            defaultPhrases.giftCard.redeemButtonTitle,
          redeemSupportingText:
            styleResponse?.style?.giftCard?.redeemSupportingText ||
            defaultPhrases.giftCard.redeemSupportingText,
        },
        guestCheckout: {
          guestCheckoutPhrase:
            styleResponse?.style?.ordering?.guestCheckoutPhrase ||
            defaultPhrases.guestCheckout.guestCheckoutPhrase,
          guestCheckoutTitle:
            styleResponse?.style?.login?.continueAsGuest.title ||
            defaultPhrases.guestCheckout.guestCheckoutTitle,
        },
        hotelPhrase:
          styleResponse?.style?.global?.hotelPhrase ||
          defaultPhrases.hotelPhrase,
        login: {
          continueAsGuestText:
            styleResponse?.style?.login?.continueAsGuest?.title ||
            defaultPhrases.login.continueAsGuestText,
          loginButtonText:
            styleResponse?.style?.login?.loginButton?.title ||
            defaultPhrases.login.loginButtonText,
          createAccountText:
            styleResponse?.style?.login?.createAccountButton?.title ||
            defaultPhrases.login.createAccountText,
          forgottenPasswordText:
            styleResponse?.style?.login?.forgottenPasswordButton?.title ||
            defaultPhrases.login.forgottenPasswordText,
        },
        loyalty: {
          cardPhrase:
            styleResponse?.style?.loyaltyCard?.loyaltyCardPhrase ||
            defaultPhrases.loyalty.cardPhrase,
          linkButtonText:
            styleResponse?.style?.loyaltyRewards
              ?.loyaltyRewardsLinkAccountButton?.title ||
            defaultPhrases.loyalty.linkButtonText,
          linkTitleText:
            styleResponse?.style?.loyaltyRewards
              ?.loyaltyRewardsLinkAccountTitle ||
            defaultPhrases.loyalty.linkTitleText,
          redeemButtonText:
            styleResponse?.style?.loyaltyRewards?.loyaltyRewardsButtonText ||
            defaultPhrases.loyalty.redeemButtonText,
          rewardBasketIndicator:
            styleResponse?.style?.loyaltyRewards
              ?.loyaltyRewardBasketIndicatorStyle ||
            defaultPhrases.loyalty.rewardBasketIndicator,
          rewardPhrase:
            styleResponse?.style?.loyaltyCard?.loyaltyRewardPhrase ||
            defaultPhrases.loyalty.rewardPhrase,
          rewardTitleText:
            styleResponse?.style?.loyaltyRewards?.loyaltyRewardsTitleText ||
            defaultPhrases.loyalty.rewardTitleText,
          siteAvailabilityPhrase:
            styleResponse?.style?.loyaltyRewards
              ?.loyaltyRewardsSiteAvailabilityPhrase ||
            defaultPhrases.loyalty.siteAvailabilityPhrase,
        },
        minAgePrompt:
          styleResponse?.style?.ordering?.minAgePrompt ||
          defaultPhrases.minAgePrompt,
        missingAllergensMessage:
          styleResponse?.style?.ordering?.missingAllergensMessage ||
          defaultPhrases.missingAllergensMessage,
        payMyBillBalanceText:
          styleResponse?.style?.ordering?.payMyBillBalanceText ||
          defaultPhrases.payMyBillBalanceText,
        payMyBillEnterTablePlaceholder:
          styleResponse?.style?.ordering?.payMyBillEnterTablePlaceholder ||
          defaultPhrases.payMyBillEnterTablePlaceholder,
        payMyBillSelectorText:
          styleResponse?.style?.ordering?.payMyBillSelectorText ||
          defaultPhrases.payMyBillSelectorText,
        payMyBillTotalText:
          styleResponse?.style?.ordering?.payMyBillTotalText ||
          defaultPhrases.payMyBillTotalText,
        payMyBillRemoveServiceChargeText:
          styleResponse?.style?.ordering?.payMyBillRemoveServiceChargeText ||
          defaultPhrases.payMyBillRemoveServiceChargeText,
        reorderMenuTitle:
          styleResponse?.style?.ordering?.inMenuReorderPhrase ||
          defaultPhrases.reorderMenuTitle,
        retrieveMyBillButtonText:
          styleResponse?.style?.ordering?.retrieveMyBillButtonText ||
          defaultPhrases.retrieveMyBillButtonText,
        roomPhrase:
          styleResponse?.style?.global?.roomPhrase || defaultPhrases.roomPhrase,
        salesAreaAtTableHeadline:
          styleResponse?.style?.ordering?.salesAreaSelector?.atTableHeadline ||
          defaultPhrases.salesAreaAtTableHeadline,
        salesAreaAtTableSubtitle:
          styleResponse?.style?.ordering?.salesAreaSelector?.atTableSubtitle ||
          defaultPhrases.salesAreaAtTableSubtitle,
        salesAreaClickAndCollectHeadline:
          styleResponse?.style?.ordering?.salesAreaSelector
            ?.clickAndCollectHeadline ||
          defaultPhrases.salesAreaClickAndCollectHeadline,
        salesAreaClickAndCollectSubtitle:
          styleResponse?.style?.ordering?.salesAreaSelector
            ?.clickAndCollectSubtitle ||
          defaultPhrases.salesAreaClickAndCollectSubtitle,
        salesAreaDeliveryToHeadline:
          styleResponse?.style?.ordering?.salesAreaSelector
            ?.deliveryToHeadline || defaultPhrases.salesAreaDeliveryToHeadline,
        salesAreaDeliveryToSubtitle:
          styleResponse?.style?.ordering?.salesAreaSelector
            ?.deliveryToSubtitle || defaultPhrases.salesAreaDeliveryToSubtitle,
        salesAreaPhrase:
          styleResponse?.style?.global?.salesAreaPhrase ||
          defaultPhrases.salesAreaPhrase,
        table:
          styleResponse?.style?.global?.tablePhrase || defaultPhrases.table,
        tipButtonText:
          styleResponse?.style?.ordering?.tipButtonText ||
          defaultPhrases.tipButtonText,
        tipSupportingText:
          styleResponse?.style?.ordering?.tipSupportingText ||
          defaultPhrases.tipSupportingText,
        venueTitle:
          styleResponse?.style?.global?.venuePhrase ||
          defaultPhrases.venueTitle,
        venueSalesAreaFormat:
          styleResponse?.style?.global?.venueSalesAreaFormat ||
          defaultPhrases.venueSalesAreaFormat,
        vouchering: {
          voucherCallToAction:
            styleResponse?.style?.vouchering?.voucherCallToAction?.title ||
            defaultPhrases.vouchering.voucherCallToAction,
          voucherButtonText:
            styleResponse?.style?.vouchering?.voucherButton?.title ||
            defaultPhrases.vouchering.voucherButtonText,
          voucherPlaceholder:
            styleResponse?.style?.vouchering?.voucherPlaceholder?.title ||
            defaultPhrases.vouchering.voucherPlaceholder,
          voucherSupportingText:
            styleResponse?.style?.vouchering?.voucherSupportingText?.title ||
            defaultPhrases.vouchering.voucherSupportingText,
        },
        waitTime:
          styleResponse?.style?.ordering?.waitTime?.phrase ||
          defaultPhrases.waitTime,
      };

      setPhrases(newPhrases);

      // update config
      const newConfig: WLWOConfig = {
        ...config,
        giftCardImage: styleResponse?.style?.giftCard?.redeemCardImage || '',
        guestCheckoutEnabled: toBoolean(
          styleResponse.style.ordering.supportsGuestCheckout,
        ),
        gradientTintColour:
          styleResponse?.style?.card?.gradientTintColor ||
          defaultConfig.gradientTintColour,
        menuLevelReorderEnabled: toBoolean(
          styleResponse.style.ordering.supportsInMenuReordering,
        ),
        supportsRewardsRedemption: toBoolean(
          styleResponse.style.loyaltyBalance?.supportsRewardRedemptions,
        ),
        shouldShowGradient: toBoolean(
          styleResponse?.style?.card?.shouldShowGradient ??
            defaultConfig.shouldShowGradient,
        ),
        showAllergensButton: toBoolean(
          styleResponse?.style?.ordering?.showAllergensButton ??
            defaultConfig.showAllergensButton,
        ),
        showAllergenPrompt: toBoolean(
          styleResponse?.style?.allergenPrompt?.showAllergenPrompt ??
            defaultConfig.showAllergenPrompt,
        ),
        supportsGiftCardRedemption: toBoolean(
          styleResponse?.style?.giftCard?.supportsGiftCardRedemption ??
            defaultConfig.supportsGiftCardRedemption,
        ),
        supportsTip: toBoolean(
          styleResponse?.style?.ordering?.supportsTip ??
            defaultConfig.supportsTip,
        ),
        vouchersEnabled: toBoolean(
          styleResponse?.style?.vouchering?.supportsVouchers ??
            defaultConfig.vouchersEnabled,
        ),
        waitTimeEnabled: toBoolean(
          styleResponse?.style?.ordering?.waitTime?.display !== undefined ||
            defaultConfig.waitTimeEnabled,
        ),
        shouldDisplayDailyCalorieAllowancePhraseOnMenu: toBoolean(
          styleResponse?.style?.calories
            ?.shouldDisplayDailyCalorieAllowancePhraseOnMenu ??
            defaultConfig.shouldDisplayDailyCalorieAllowancePhraseOnMenu,
        ),
        shouldPrefixRoomPhrase: toBoolean(
          styleResponse?.style?.chargeToRoom?.shouldPrefixRoomPhrase ??
            defaultConfig.shouldPrefixRoomPhrase,
        ),
        showFolioBalance: toBoolean(
          styleResponse?.style?.chargeToRoom?.folioSelector?.showFolioBalance ??
            defaultConfig.showFolioBalance,
        ),
        showResetLoyaltyPin: toBoolean(
          styleResponse?.style?.loyaltyRewards?.showsResetPINOption ??
            defaultConfig.showResetLoyaltyPin,
        ),
      };

      setConfig(newConfig);

      // TODO: remove this eventually
      window.config = newConfig;
    } else {
      setInitError((styleData as ErrorResponse)?.detail);
    }

    setStyleLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleData, configLoaded]);

  const setExternalConfig = (configKey: string, value: unknown) => {
    console.log('updating config', configKey, value);

    const newConfig: WLWOConfig = {
      ...config,
      [configKey]: value,
    };

    console.log('newConfig', newConfig);

    setConfig(newConfig);
  };

  const setExternalPhrases = (configKey: string, value: unknown) => {
    console.log('updating phrases', configKey, value);

    const newPhrases: WLWOPhrases = {
      ...phrases,
      [configKey]: value,
    };

    console.log('newPhrases', newPhrases);

    setPhrases(newPhrases);
  };

  useEffect(() => {
    if (configError) {
      setInitError('Loading config error: ' + configError.toString());
    }
  }, [configError]);

  useEffect(() => {
    if (styleError) {
      setInitError('Loading style error: ' + styleError.toString());
    }
  }, [styleError]);

  useEffect(() => {
    if (cssError) {
      setInitError('Loading css error: ' + cssError.toString());
    }
  }, [cssError]);

  const getDefaultDailyCaloriePhrase = (
    currency: string,
    defaultCaloriePhrase: string,
    dailyCalorieAllowancePhrase?: string,
  ) => {
    if (
      dailyCalorieAllowancePhrase === undefined ||
      dailyCalorieAllowancePhrase === ''
    ) {
      return currency.toUpperCase() === 'USD'
        ? defaultCaloriePhraseUsLocale
        : defaultCaloriePhrase;
    }

    return dailyCalorieAllowancePhrase;
  };

  return (
    <ConfigContext.Provider
      value={{
        config,
        phrases,
        setConfig: setExternalConfig,
        setPhrases: setExternalPhrases,
      }}
    >
      {configLoaded === false ||
      styleLoaded === false ||
      cssLoading === true ? (
        <div className="data-loader-wrapper">
          <Loader />
        </div>
      ) : initError ? (
        <>
          <Helmet title={`There was an error`} />
          <div className="error-container">
            <img src={errorImage} alt="Hilarious error" />
            <h1>Oops!</h1>
            <p>There was an error when initialising, the error message was:</p>
            <span>{initError}</span>
          </div>
        </>
      ) : (
        <>
          <Helmet>
            <style>{cssData}</style>
          </Helmet>
          {children}
        </>
      )}
    </ConfigContext.Provider>
  );
};
