import { FormattedCurrency } from 'common/components/FormattedCurrency';

interface BillTableRowProps {
  name: string;
  amount: number;
  isReduction?: boolean;
  additionalClasses?: string;
}

export const BillTableRow: React.FC<BillTableRowProps> = ({
  name,
  amount,
  isReduction,
  additionalClasses = '',
}) => {

  return (
    <div className={`bill-line ${additionalClasses}`}>
      <div className='bill-description'>{name}</div>
      <div className="text-right">
        <FormattedCurrency value={isReduction ? -amount : amount} />
      </div>
    </div>
  );
};
